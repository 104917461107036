import React from 'react';

import FeaturedCategoryOne from './cards/FeatureCategoryOne';
import FeaturedCategoryTwo from './cards/FeatureCategoryTwo';
import FeaturedCategoryThree from './cards/FeatureCategoryThree';
import FeaturedCategoryFour from './cards/FeatureCategoryFour';

const FeaturedCategories = () => {
  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <div className="row m-0">
      <div className="col-6 hover-shadow p-0">{/* <FeaturedCategoryOne /> */}</div>
      <div className="col-6 hover-shadow p-0">{/* <FeaturedCategoryTwo /> */}</div>
      <div className="col-6 hover-shadow p-0">{/* <FeaturedCategoryThree /> */}</div>
      <div className="col-6 hover-shadow p-0">{/* <FeaturedCategoryFour /> */}</div>
    </div>
  );
};
export default FeaturedCategories;
