import React from 'react';
import PropTypes from 'prop-types';
import 'prismjs/themes/prism-tomorrow.css';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import loadable from '@loadable/component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookReader, faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';
import Layout from '../layout';
import SEO from '../components/SEO';
import { useIsMobile } from '../components/utils';
import './post.scss';
import Bio from '../components/bio';
import config from '../../config/siteConfig';
import FeaturedCategories from '../components/queries/category/category-strip';

const PostPrevNext = ({ prev, next }) => {
  const isMobile = useIsMobile();
  return (
    <div className="post-prev-next">
      {prev &&
        (isMobile ? (
          <Link to={prev.fields.slug} rel="prev" className="mobile-post-prev">
            <FontAwesomeIcon icon={faArrowLeft} />
          </Link>
        ) : (
          <Link to={prev.fields.slug} rel="prev" className="post-prev">
            <FontAwesomeIcon icon={faArrowLeft} />
            {/* <p className="lead"> {prev.frontmatter.title}</p> */}
          </Link>
        ))}
      {next &&
        (isMobile ? (
          <Link to={next.fields.slug} rel="next" className="mobile-post-next">
            <FontAwesomeIcon icon={faArrowRight} />
          </Link>
        ) : (
          <Link to={next.fields.slug} rel="next" className="post-next">
            {/* {next.frontmatter.title} */}
            <FontAwesomeIcon icon={faArrowRight} />
          </Link>
        ))}
    </div>
  );
};

const Post = ({ data, pageContext }) => {
  const post = data.markdownRemark;
  const { html, excerpt, frontmatter } = post;
  const { title, cover, date } = frontmatter;
  const { slug, prev, next } = pageContext;
  const Disqus = loadable(() => import('../components/disqus'));

  return (
    <Layout>
      <SEO title={title} description={excerpt} image={cover.publicURL} path={slug} articleDate={date} />
      <div className="container-fluid">
        <div className="row max-vh-80 card overflow-hidden border-0 align-content-center rounded-0 justify-content-center  ">
          {cover && <Img fluid={cover.childImageSharp.fluid} className="w-100 min-vh-80" />}
          <div className="card-img-overlay m-0 row align-content-end justify-content-center">
            <div className="col-md-8 mx-auto p-3 align-self-end">
              <div className="col-md-12 p-0 align-self-start">
                {frontmatter.category && (
                  <Link
                    className=" badge badge-pill badge-primary my-2 p-3 rounded-0 "
                    to={`/categories/${_.kebabCase(frontmatter.category)}`}
                  >
                    {frontmatter.category}
                  </Link>
                )}
              </div>
              <h1 className="card-title text-white py-3 m-0 text-left ">{frontmatter.title}</h1>
              <div className="col-md-4  p-0 post justify-content-start">
                <div className="col p-0 justify-content-end align-self-center">
                  <FontAwesomeIcon className="text-white" icon={faBookReader} />
                  <small className="text-right text-white p-2 ">
                    {post.timeToRead}
                    &nbsp;min
                  </small>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-9 p-0 mx-auto">
          <div className="row justify-content-center mt-5">
            <div className="col-md-8">
              <p className="small text-primary">{frontmatter.date}</p>
              <div className="markdowm-body" dangerouslySetInnerHTML={{ __html: html }} />
            </div>
            <div className="col-md-3">
              <div className="card border-0">
                <Bio config={config} />
                <hr />
                <h4 className="text-center">Featured categories</h4>
              </div>
              <FeaturedCategories />
            </div>
          </div>
          <div className="py-4">
            <PostPrevNext prev={prev} next={next} />
            <Disqus slug={slug} title={title} />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      excerpt
      timeToRead
      frontmatter {
        title
        tags
        cover {
          publicURL
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        date(formatString: "MMMM DD YYYY")
        category
      }
    }
  }
`;

Post.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      html: PropTypes.string.isRequired,
      excerpt: PropTypes.string.isRequired,
      timeToRead: PropTypes.number.isRequired,
      frontmatter: PropTypes.shape({
        title: PropTypes.string.isRequired,
        tags: PropTypes.arrayOf(PropTypes.string),
        cover: PropTypes.object,
        date: PropTypes.string,
        category: PropTypes.string,
      }).isRequired,
    }).isRequired,
  }).isRequired,
  pageContext: PropTypes.shape({
    slug: PropTypes.string.isRequired,
    prev: PropTypes.shape({
      fields: PropTypes.shape({
        slug: PropTypes.string.isRequired,
      }),
      frontmatter: PropTypes.shape({
        title: PropTypes.string.isRequired,
      }),
    }),
    next: PropTypes.shape({
      fields: PropTypes.shape({
        slug: PropTypes.string.isRequired,
      }),
      frontmatter: PropTypes.shape({
        title: PropTypes.string.isRequired,
      }),
    }),
  }).isRequired,
};

PostPrevNext.propTypes = {
  prev: PropTypes.shape({
    fields: PropTypes.shape({
      slug: PropTypes.string.isRequired,
    }),
    frontmatter: PropTypes.shape({
      title: PropTypes.string.isRequired,
    }),
  }),
  next: PropTypes.shape({
    fields: PropTypes.shape({
      slug: PropTypes.string.isRequired,
    }),
    frontmatter: PropTypes.shape({
      title: PropTypes.string.isRequired,
    }),
  }),
};

PostPrevNext.defaultProps = {
  prev: null,
  next: null,
};

export default Post;
