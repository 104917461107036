/**
 * Bio component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faFacebook, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import Image from './avatar';
import Social from '../../templates/social';

function Bio() {
  const bioQuery = graphql`
    query BioQuery {
      site {
        siteMetadata {
          author
          twitterUsername
        }
      }
    }
  `;

  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <StaticQuery
      query={bioQuery}
      render={data => {
        const { author } = data.site.siteMetadata;
        const { twitterUsername } = data.site.siteMetadata;
        return (
          <div>
            <div className="card border-0">
              <Image alt="Gatsby in Space" className="rounded-circle img-thumbnail" filename="dog.jpg" />
              <div className="card-body">
                <h5 className="card-title text-center">{author}</h5>
                <p className="card-text text-muted text-center">
                  The best things in life are those that spark your interest. Those that call to your soul.
                </p>
              </div>
              <div className="card-body text-center">
                <Social />
              </div>
            </div>
          </div>
        );
      }}
    />
  );
}

export default Bio;
